import { Link, BrowserRouter, useHistory as Router, Route, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

import './Controls.scss';

import back from './back.svg';
import cancel from './cancel.svg';
import repeat from './repeat.svg';
import X2 from './X2.svg';

import bet from './bet.svg';
import win from './win.svg';

/* stores */
import betStore from '../stores/bet-store';
import historyStore from '../stores/history-store';
import tableStore from '../stores/table-store';
import soundStore from '../stores/sound-store';

import currencyConfig from '../utils/currency';

const Controls = observer((props) => {

	const { t } = useTranslation();

	let [chips, setChips] = useState(null);
	let [currentChip, setCurrentChip] = useState(null);

	let [statistic] = useState({
		red: 50,
		green: 4,
		black: 46,
	})

	let chooseChip = (e) => {
		setCurrentChip(e.value);
		props.chooseChip(e)
		soundStore.playChange();
	}

	// apply current currency
	useEffect(() => {
		console.log("Controls betStore.currency", betStore.currency)
		if(betStore.currency && currencyConfig[betStore.currency]) {
			setChips(currencyConfig[betStore.currency].chips);
			setCurrentChip(currencyConfig[betStore.currency].chips[0].value);
			props.chooseChip(currencyConfig[betStore.currency].chips[0]);
			console.log("Controls successfully setted currency ", betStore.currency);
		} else {
			setChips(currencyConfig.default.chips);
			setCurrentChip(currencyConfig.default.chips[0].value);
			props.chooseChip(currencyConfig.default.chips[0]);
			console.log("Controls successfully default currency");
		}
	}, [betStore.currency])


  	return (
		<div className="Controls d-flex align-items-center justify-content-between">
			<div className="info__block">
				<div className="info mb-1"><img src={bet} alt=""/><span> {t('bet')}: {props.bet}</span></div>
				<div className="info"><img src={win} alt=""/><span> {t('win')}: {props.win}</span></div>
			</div>
			<div className="d-flex align-iems-center chips">
				<div className="d-flex align-iems-center buttons mright">
					<button onClick={() =>{if (!tableStore.block) betStore.back()}}><img src={back} alt="" /></button>
					<button onClick={() => {if (!tableStore.block) betStore.cancel()}}><img src={cancel} alt="" /></button>
				</div>
				<div className="d-flex align-iems-center">
					{chips ? chips.map((chip, key) => 
						<div key={key} className={"chip__holder " + (currentChip === chip.value ? "current" : "")}><div className="currentChip"></div><img onClick={(e) => chooseChip(chip)} className="chip" src={chip.img} alt=""/></div>
					) : ""}
				</div>
				<div className="d-flex align-iems-center buttons mleft">
					<button onClick={() => {if (!tableStore.block) betStore.X2()}}><img src={X2} alt=""/></button>
					<button onClick={() => {if (!tableStore.block) betStore.repeat()}}><img src={repeat} alt=""/></button>
				</div>
			</div>
			<div className="last__block">
				<div className="statistic__block">
					<div className="statistic">
						<div className="position-absolute red" style={{width: statistic.red + "%"}}></div>
						<div className="position-absolute green" style={{left: statistic.red + "%", width: statistic.green + "%"}}></div>
						<div className="position-absolute black" style={{left: statistic.red + statistic.green + "%", width: statistic.black + "%"}}></div>
					</div>
					<div className="position-relative">
						<span className="position-absolute red" style={{left: 0}}>{t('red')}: {statistic.red}%</span>
						<span className="position-absolute green" style={{left: statistic.red + "%"}}>{statistic.green}%</span>
						<span className="position-absolute black" style={{right: 0}}>{t('black')}: {statistic.black}%</span>
					</div>
					<div className="inscription d-flex align-iems-center justify-content-center">
						<span>{t('last')}</span>
					</div>
				</div>
			</div>
		</div>
  	)
})

export default Controls;