// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html, body {\n  font-family: \"Roboto\", sans-serif;\n  height: 100%;\n  width: 100%;\n  font-size: 12px;\n}\n@media (min-width: 992px) {\n  html, body {\n    font-size: 14px;\n  }\n}\n@media (min-width: 1200px) {\n  html, body {\n    font-size: 16px;\n  }\n}\n\n* {\n  border: 0;\n  outline: 0;\n  border-radius: 0;\n}\n\n#root {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACE,iCAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AADF;AAEE;EALF;IAMG,eAAA;EACD;AACF;AAAE;EARF;IASG,eAAA;EAGD;AACF;;AAAA;EACC,SAAA;EACA,UAAA;EACA,gBAAA;AAGD;;AACA;EACC,YAAA;AAED","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');\r\n\r\n\r\nhtml, body {\r\n  font-family: 'Roboto', sans-serif;\r\n  height: 100%;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  @media(min-width: 992px) {\r\n  \tfont-size: 14px;\r\n  }\r\n  @media(min-width: 1200px) {\r\n  \tfont-size: 16px;\r\n  }\r\n}\r\n\r\n* {\r\n\tborder: 0;\r\n\toutline: 0;\r\n\tborder-radius: 0;\r\n}\r\n\r\n\r\n#root {\r\n\theight: 100%\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
