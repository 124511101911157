import {
  makeAutoObservable,
  observable,
  action,
  computed,
  autorun,
  toJS,
} from "mobx";

import soundStore from "./sound-store";
import balanceStore from "./balance-store";

import currencyConfig from "../utils/currency";

class BetStore {
  bets;
  betsLog;
  lastBets;
  notify;

  constructor() {
    this.bets = [];
    this.betsLog = [];
    this.lastBets = [];
    this.notify = null;
    this.currency = null;
    this.language = null;
    this.isKazPlayer = false;

    makeAutoObservable(this);
  }

  setCurrency(currency) {
    this.currency = currency;
  }
  setLanguage(language) {
    this.language = language;
  }

  setIsKazPlayer(value) {
    this.isKazPlayer = value;
  }

  getBetsSum() {
    let result = 0;
    this.bets.forEach((item) => {
      result += item.money.reduce((sum, item) => sum + item, 0);
    });
    return result;
  }

  addBets(value, chip, limitType) {
    console.log("this.bets", this.bets);

    // check if there is enough money on the balance
    let sum = this.getBetsSum();
    if ((
      !toJS(balanceStore.demo) &&
      toJS(balanceStore.realBalance) - (sum + chip.value) < 0
    ) || (toJS(balanceStore.demo) &&
      toJS(balanceStore.demoBalance) - (sum + chip.value) < 0)) {
      this.notify = { type: "not_money" };
      setTimeout(() => {
        this.notify = null;
      }, 2000);
      return;
    }

    // get current limits for this curreny
    let currentCurrencyLimit =
      this.currency && currencyConfig[this.currency]
        ? currencyConfig[this.currency].limits
        : currencyConfig.default.limits;

    // check whether there have already been bets on this cell
    let isEqual = false;
    let indexEl;
    if (this.bets.length > 0) {
      this.bets.forEach((item, index) => {
        if (JSON.stringify(item.bet) === JSON.stringify(value)) {
          isEqual = true;
          indexEl = index;
          return;
        }
      });
    }

    // 2 variants: there are already bets on this cell or not
    if (isEqual) {
      // get info about bets on this cell
      let currEl = this.bets[indexEl];
      let previousCellMoney = currEl.money.reduce((sum, item) => sum + item, 0);

      // check maximum bet limit for this cell
      if (
        previousCellMoney + chip.value >
        currentCurrencyLimit[limitType].max
      ) {
        this.notify = {
          type: "max_limit_fail",
          info: currentCurrencyLimit[limitType].max,
        };
        setTimeout(() => {
          this.notify = null;
        }, 2000);
        return;
      }

      // check minimum bet limit for this cell
      if (
        previousCellMoney + chip.value <
        currentCurrencyLimit[limitType].min
      ) {
        this.notify = {
          type: "min_limit_fail",
          info: currentCurrencyLimit[limitType].min,
        };
        setTimeout(() => {
          this.notify = null;
        }, 2000);
        return;
      }

      this.bets[indexEl] = {
        bet: currEl.bet,
        money: [...currEl.money, ...[chip.value]],
        limitType: limitType,
      };
    } else {
      // check maximum bet limit for this cell
      if (chip.value > currentCurrencyLimit[limitType].max) {
        this.notify = {
          type: "max_limit_fail",
          info: currentCurrencyLimit[limitType].max,
        };
        setTimeout(() => {
          this.notify = null;
        }, 2000);
        return;
      }

      // check minimum bet limit for this cell
      if (chip.value < currentCurrencyLimit[limitType].min) {
        this.notify = {
          type: "min_limit_fail",
          info: currentCurrencyLimit[limitType].min,
        };
        setTimeout(() => {
          this.notify = null;
        }, 2000);
        return;
      }

      this.bets.push({
        bet: value,
        money: [chip.value],
        limitType: limitType,
      });
    }

    console.log("bet");
    if(!toJS(balanceStore.demo)) {
      // real
      balanceStore.updateBalance(
        toJS(balanceStore.realBalance) - (sum + chip.value)
      );
    } else {
      // demo
      balanceStore.updateBalance(
        toJS(balanceStore.balance) - chip.value
      );
    }
    soundStore.playChip();
    this.betsLog.push(value);
  }

  X2() {
    if (this.bets.length === 0) return;
    let sum = this.getBetsSum();

    // check if there is enough money on the balance
    if ((
      !toJS(balanceStore.demo) &&
      toJS(balanceStore.realBalance) - sum * 2 < 0
    ) || (toJS(balanceStore.demo) &&
      toJS(balanceStore.demoBalance) - sum * 2 < 0)) {
      this.notify = { type: "not_money" };
      setTimeout(() => {
        this.notify = null;
      }, 2000);
      return;
    }

    // check maximum bet limit for this cell
    for (let i = 0; i < this.bets.length; i++) {
      // get info about bets on this cell
      let previousCellMoney = this.bets[i].money.reduce(
        (sum, item) => sum + item,
        0
      );
      let limitType = this.bets[i].limitType;
      let currentCurrencyLimit =
        this.currency && currencyConfig[this.currency]
          ? currencyConfig[this.currency].limits
          : currencyConfig.default.limits;

      if (previousCellMoney * 2 > currentCurrencyLimit[limitType].max) {
        this.notify = {
          type: "max_limit_fail",
          info: currentCurrencyLimit[limitType].max,
        };
        setTimeout(() => {
          this.notify = null;
        }, 2000);
        return;
      }
    }

    // update bets
    for (let i = 0; i < this.bets.length; i++) {
      let newChips = this.bets[i].money;
      this.bets[i] = {
        bet: this.bets[i].bet,
        money: [...this.bets[i].money, ...newChips],
        limitType: this.bets[i].limitType,
      };
    }
    if(!toJS(balanceStore.demo)) {
      // real
      balanceStore.updateBalance(toJS(balanceStore.realBalance) - sum * 2);
    } else {
      // demo
      balanceStore.updateBalance(toJS(balanceStore.demoBalance) - sum * 2);
    }
    soundStore.playChip();
    this.betsLog.push("x2");
  }

  back() {
    if (!this.betsLog.length) return;

    const lastBet = this.betsLog.pop();
    if (lastBet === "repeat") {
      this.bets = [];
      return;
    }

    this.bets.forEach((bet) => {
      if (lastBet === "x2") {
        const halfIndex = Math.floor(bet.money.length / 2);
        // remove second part of array and save it
        const removedChips = bet.money.splice(halfIndex, bet.money.length - halfIndex);
        // Sum all removed chips
        const sumRemoved = removedChips.reduce((acc, chip) => acc + chip, 0);
        // real and demo
        balanceStore.updateBalance(balanceStore.balance + sumRemoved);
      } else if (JSON.stringify(bet.bet) === JSON.stringify(lastBet)) {
        if (bet.money.length > 0) {
          const poppedChip = bet.money.pop();
          // real and demo
          balanceStore.updateBalance(balanceStore.balance + poppedChip);
        }
      }
    });
  }

  cancel() {
    console.log("cancelcancel");
    this.betsLog = [];
    this.bets = [];
    if(!toJS(balanceStore.demo)) {
      // real
      balanceStore.updateBalance(toJS(balanceStore.realBalance));
    } else {
      // demo
      balanceStore.updateBalance(toJS(balanceStore.demoBalance));
    }
  }

  clear() {
    console.log("clear");
    if (this.bets.length === 0 && this.lastBets.length !== 0) return;
    this.lastBets = this.bets;
    this.betsLog = [];
    this.bets = [];
  }

  repeat() {
    console.log("repeat", this.lastBets);
    this.bets = this.lastBets;
    let sum = this.getBetsSum();
    if ((!toJS(balanceStore.demo) && toJS(balanceStore.realBalance) - sum < 0) || (toJS(balanceStore.demo) && toJS(balanceStore.demoBalance) - sum < 0) ) {
      this.notify = { type: "not_money" };
      this.bets = [];
      setTimeout(() => {
        this.notify = null;
      }, 2000);
      return;
    }
    if (this.lastBets.length !== 0) soundStore.playChip();

    if(!toJS(balanceStore.demo)) {
      // real
      balanceStore.updateBalance(toJS(balanceStore.realBalance) - sum);
    } else {
      // demo
      balanceStore.updateBalance(toJS(balanceStore.demoBalance) - sum);
    }

    this.betsLog.push("repeat");
  }
}

const betStore = new BetStore();

export default betStore;
export { BetStore };
